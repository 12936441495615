<template>
    <div class="footer-content text-center">
        <b-container class="social">
            <div
                class="d-flex flex-lg-row flex-column w-100 align-items-lg-center justify-content-lg-between justify-content-center"
            >
                <a
                    class="d-block mb-3 mb-lg-0 footer-logo"
                    href="https://mmforbiz.com/"
                >
                    <img
                        :src="require('@assets/images/logo-black.svg')"
                        style="max-width: 100%; height: auto"
                        alt="MatchMaker4Business"
                    />
                </a>
            </div>
        </b-container>
        <b-container class="copyright">
            Copyright &copy; {{ copyrightYear }} MatchMaker4Business | Design by
            <a href="https://ideamaker.agency/" target="_blank">Idea Maker</a>
            |
            <a
                href="https://mmforbiz.com/terms-and-conditions/"
                target="_blank"
                >Terms of Use</a
            >
            |
            <a href="https://mmforbiz.com/privacy-policy/"
                >Privacy Policy
            </a>
        </b-container>
    </div>
</template>

<script>
export default {
    name: 'ContentFooter',
    computed: {
        copyrightYear() {
            return new Date().getFullYear()
        },
    },
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 991px) {
    .footer-logo img {
        width: 295px;
    }
    .patent-text {
        font-size: 24px !important;
    }
}
</style>
